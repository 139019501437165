#mainImg{
    width: 100%;
    margin-bottom: 50px;
    position: relative;
    background-color: black;
}
#mainImg img {
    width: 100%;
}

#mainIntro{
    text-align: center;
    /* font-size: clamp(1rem, 1vw, 1.2rem); */
    font-size: 1.1rem;
    margin: 30px 0;
}
  
.textCenter {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 30px;
}
  
#mainContainer {
    width: 100%;
    margin: 0 auto;
    text-align: center;
}
  
#mainReaderWrap{
    margin-bottom: 80px;
}
#readerMainImg img {
    width: 100%;
}
#readers {
    display: flex;
    justify-content: space-between;
    margin: 3px;
}
#readers div img{
    width: 95%;
    margin: 0 auto;
}

.mainPrdDiv{
    width: 97%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    overflow:auto; 
}
.mainPrdDiv img{
    border: 1px solid #d9d9d9;
    margin: 0 5px;
    width: 250px;
}
.mainPlusBtn{
    margin-bottom: 80px;
}
.mainPlusBtn img{
    width: 25%;
}

.subImgDivMain {
    width: 100%;
    margin-bottom: 80px;
}
.subImgDivMain img {
    width: 100%;
}

.subImgDiv {
    width: 100%;
    margin: 15px 0;
}
.subImgDiv img:first-child {
    width: 90%;
}
.subImgDiv img:last-child {
    width: 70%;
}
.subImgDiv2 img {
    width: 85%;
    margin-bottom: 80px;
}

#infoWrap{
    width: 95%;
    display: flex;
    margin: 0 auto;
    margin-bottom: 100px;
}
.infoDiv img {
    width: 100%;
    background-color: #ffffff;
}

.infoDiv {
    position: relative;
}
.hoverOn {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
}
.infoDiv:hover .hoverOn{
    display: block;
}

/* .sideBanner {
    position: fixed;
    width: 100%;
    text-align: end;
    right: 10px;
    bottom: 5px;
    z-index: 1;
} */

.swiper {
    width: 100%;
    --swiper-theme-color:#fff;
}
  
.swiper-slide {
    text-align: center;
}
  
.swiper-pagination-bullet {
    --tw-ring-color: #fff;
} 
  
.swiper-pagination-bullet {
    background : var(--swiper-pagination-bullet-inactive-color, #fff);
}
