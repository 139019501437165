.table {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
}
table.table > thead {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}
table.table > thead th {
  padding: 15px 0 15px 10px;
  border-bottom: 1px solid black;
  text-align: start;
}
table.table > thead th p {
  margin: 0;
  text-align: start;
}
table.table > tbody td {
  border-bottom: 1px solid lightgray;
  text-align: start;
  vertical-align: top;  
}
table.table > tbody td:first-child {
  padding: 15px 0 15px 10px;
}
table.table > tbody td input {
  width: 100%;
  border: none;
  background-color: #EAEAEA;
  /* height: 35px; */
  padding: 10px;
  text-align: start;
}
table.table > tbody td textarea {
  width: 100%;
  border: none;
  background-color: #EAEAEA;
  height: 250px;
  padding: 10px;
  text-align: start;
}

.point {
  color: #1782EA;
  font-weight: bold;
}

.security {
  display: flex;
  align-items: center;
  padding-left: 10px;
  margin-bottom: 50px;
}
.securityText {
  text-align: start;
}

.securityTextRight {
	position: relative;
  display: flex;
}

.jbwrap {
	position: relative;
  display: flex;
  align-items: center;
  padding-top: 10px;
}
.jbwrap img {
	width: 100%;
	vertical-align: middle;
}
.jbtext {
	padding: 5px 10px;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate( -50%, -50% );
  padding-top: 10px;
  font-size: clamp(2rem, 5vw, 3rem);
}
.refresh {
  margin-left: 10px;
  padding-top: 10px;
}
.refresh p {
  margin: 0;
  text-align: start;
}

.agreedBox {
  margin: 0 auto;
}
.agreedText {
  padding: 15px;
	border: 1px solid #7C7B81;
  height: 300px;
  overflow: auto;
}
.agreedText::-webkit-scrollbar {
  background-color: #EAEAEA;
  width: 5px;
}
.agreedText::-webkit-scrollbar-thumb {
  background-color: #1782EA;
}
.agreedText p {
  text-align: start;
  color: #7C7B81;
  line-height: 170%;
}
.agreedText p:first-child {
  color: #000000;
  font-weight: bold;
  font-size: 1.1rem;
  margin: 20px 0;
}

.checkbox {
  text-align: end;
  margin: 15px;
}
.checkbox input {
  cursor: pointer;
}
.checkbox label {
  margin-left: 5px;
  cursor: pointer;
}
.checkBtnDiv {
  text-align: center;
}



@media screen and (min-width: 768px) {
  .table {
    min-width: 1200px;
  }
  .agreedBox {
    max-width: 1200px;
  }
  table.table > thead th:first-child {
    width: 250px;
  }
  .securityText {
    width: 250px;
  }
  .refresh p {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .security {
    margin-right: 10px;
  }
  .securityText {
    width: 30%;
  }
  .securityTextRight {
    width: 80%;
  }
  .jbwrap {
    width: 50%;
  }
  
  .refresh {
    width: 50%;
    height: 56px;
  }
  .refresh p {
    font-size: 0.8rem;
  }
  .refresh a {
    width: 100%;
  }
  .refresh input {
    width: 100%;
  }
}

