.prdDlWrap{
  display: flex;
  flex-direction: column;
  margin: 5%;
  justify-content: space-around;
  align-items: center;
}
.prdDlWrap > *{
  margin: 2%;
  text-align: center;
}
#prdDlTitle{
  font-size: 2rem;
}
#prdDlContent{
  font-size: 1.4rem;
}
#prdDlPwdInput{
  padding: 0.4%;
  border: 1px solid #DADADA;
  outline: none;
  width: 27%;
  font-size: 1.2rem;
}
#prdDlSubmitBtn{
  border-radius: 5px;
  border: none;
  background: #1782EA;
  color: white;
  width: 20%;
  padding: 0.5%;
  font-size: 1.3rem;
}
.blueFont{
  color: #1782EA;
}