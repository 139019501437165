.prdDlWrap{
  display: flex;
  flex-direction: column;
  margin: 10%;
  justify-content: space-around;
  align-items: center;
  /* border: 1px solid black; */
}
.prdDlWrap > *{
  margin: 5vw;
  text-align: center;
}
#prdDlTitle{
  font-size: 4.1vw;
}
#prdDlContent{
  font-size: 2.7vw;
}
#prdDlPwdInput{
  padding: 1vw 10%;
  border: 1px solid #DADADA;
  outline: none;
  width: 60%;
  font-size: 2.2vw;
}
#prdDlSubmitBtn{
  border-radius: 5px;
  border: none;
  background: #1782EA;
  color: white;
  width: 50%;
  height: 8vw;
  font-size: 2.6vw;
}
.blueFont{
  color: #1782EA;
}