header{
  display: flex;
}
.headerWrapper{
  width: 100%;
}
.header {
  width: 100%;
  /* position: fixed;
  top: 0;
  z-index: 99; */
}

/* @media screen and (min-width: 768px) {
    .headerWrapper{
        min-width: 1200px;
    }
    .header {
        min-width: 1200px;
    }
} */



#navMenu {
  width: 100%;
}
#navMenu * {
  text-decoration: none;
  width: 12.5%;
}
#headerLogo{
  text-align: center;
  width: 15%
}
#navMenu {
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  width: 1200px;
}
#navMenu li{
  cursor: pointer;
  padding: 25px 0;
  width: 100%;
  text-align: center;
  color: black;
  font-weight: 500;
  font-size: 1.1rem;
  white-space: nowrap;
}
#navMenu li:hover{
  box-sizing: inherit;
  background-color: #000000;
  color: #ffffff;
}
#navMenu a div {
  width: 100%;
}
#navMenu a div ul {
  position: fixed;
  z-index: 999;
  background-color: #eaeaea;
  padding: 0;
  margin: 0;
  width: 147px;
}
#navMenu a div ul li {
  text-decoration: none;
  list-style: none;
  font-size: 1rem;
  font-weight: 300;
  padding: 5px 0;
}
#navMenu a div ul li:hover {
  background-color: #eaeaea;
  color:#000000;
  font-weight: bold;
}

/* #navSubMenu {
  display: flex;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  top: 76px;
  z-index: 999;
}
#navSubMenu div {
  width: 100%;
} */
/* #customerSubMenu {
  border: 1px solid red;
  padding: 0;
  margin-bottom: 15px;
  background-color: #ffffff;
  position: fixed;
  left: 56%;
}
#customerSubMenu a {
  text-decoration: none;
  list-style: none;
  color: black;
}
#customerSubMenu a li {
  font-size: 1rem;
  font-weight: 300;
}
#customerSubMenu a li:hover {
  color: black;
  font-weight: bold;
} */

#mainLogo{
  width: 150px;
  height: 25px;
}
#searchSection{
  display: flex;
  justify-content: flex-end;
  margin: 0;
  width: 100%;
  background-color: #F1F1F1;
  height: 40px;
  min-width: 1200px;
}
#searchSecDiv {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  max-width: 1200px;
  margin: 0 auto;
}
#searchWrap{
  display: flex;
  justify-content: flex-end;
  height: 100%;
  width: 30%;
}
#searchIcon{
  margin-left: 7px;
  margin-right: 20px;
  width: 25px;
  cursor: pointer;
}
#searchInputDiv{
  width: 90%;
}
#searchInput{
  height: 90%;
  width: 100%;
  background-color: #F1F1F1;
  outline: none;
  border-style: none;
  border-bottom: 1px solid #BCBCBC;
}


