.prdCardWrap{
  display: flex;
  flex-direction: row;
  width: 33%;
  align-items: center;
  justify-content: center;
  position: relative;
  height: auto;
  margin-top: 50px;
}
.prdCardThumbImg{
  border: 2px solid #ffffff;
  width: 100%;
  height: auto;
}
.prdCardThumbImg:hover{
  /* border: 2px solid #000000; */
  /* border-radius: 2%; */
  -webkit-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  -ms-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  -o-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.prdCardDownloadText{
  /* border: 1px solid red; */
  position: absolute;
  width: 45%;
  height: 10%;
  left: 52%;
  bottom: 2%;
  z-index: 1;
  cursor: pointer;
}
.prdCardDownBrc {
  /* border: 1px solid blue; */
  position: absolute;
  width: 40%;
  height: 10%;
  left: 8%;
  bottom: 2%;
  z-index: 1;
  cursor: pointer;
}