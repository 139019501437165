/* #bottum-modal-container{
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.502);
}

#bottum-modal-content{
  position: absolute;
  width: 100%;
  height: 60%;
  background-color: white;
  bottom: 0;
  border-radius: 100px 100px 0 0;
  z-index: 400;
} */

/* 그대로 사용 */

/* 모달 스타일 */
#bottom-modal-container {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.502);
  display: flex;
  align-items: flex-end;
  z-index: 300;
  animation: fadein 0.5s ease-in-out;
}

#bottom-modal-wrap {
  position: absolute;
  width: 100%;
  height: 60%;
  background-color: white;
  bottom: 0;
  border-radius: 8% 8% 0 0;
  z-index: 400;
  animation: slideup 0.5s ease-in-out;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideup {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}



