.footerWrapper {
    background-color: #f2f2f2;
    width: 100%;
}
.footerWrapper2 {
    background-color: #ededed;
    width: 100%;
}

.footer {
    margin: 0 auto;
    width: 50%;
    align-items: center;
    position: relative;
    display: block;
}

.logo {
    text-align: start;
    padding: 30px;
    padding-top: 40px;
    border-bottom: 2px solid #CECECE;
}


.introduceAll {
    display: flex;
    width: 100%;
    align-items: baseline;
    justify-content: space-between;
    padding: 30px 0;
}

.introduce:first-child {
    margin-left: 30px;
}
.introduce > p {
    text-align: start;
    margin: 0;
}
.title {
    font-weight: bold;
    font-size: 1.6rem;
}
.mid {
    padding: 20px 0;
}
.mid, .midLast {
    font-size: 1.3rem;
}
.sub {
    font-size: 0.9rem;
    text-align: start;
}
.sub > a:hover {
    color: #1782EA;
}

.address {
    padding: 30px;
}
.address> p> a {
    color: black;
    text-decoration: none;
}

.certLogo > img{
    width: 100%;
    max-width: 1109;
    object-fit: cover;
    padding: 20px;
}

.mobileFooter {
    background-color: #f2f2f2;
    text-align: center;
}
.mobileLink a {
    color: black;
    text-decoration: none;
}
.mobileLink a:hover {
    color: #1782EA;
}

@media screen and (min-width: 768px) {
    .footerWrapper, .footerWrapper2 {
        min-width: 1200px;
    }
    .container {
        min-width: 1200px;
    }
    .footer {
        min-width: 1200px;
    }
    .mobileFooter {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .footerWrapper, .footerWrapper2 {
        display: none;
    }
    .mobileFooter {
        display: block;
        position: relative;
        background-color: #f2f2f2;
    }
    .mobileLink {
        padding: 20px;
    }
    .mobileFooter p {
        margin: 20px;
    }
    .mobileMid{
        font-size: clamp(0.9rem, 3.5vw, 1.1rem);
        font-weight: bold;
        padding-bottom: 10px;
    }
    .mobileSub {
        font-size: clamp(0.7rem, 3vw, 1rem);
    }
    .mobileLogo {
        width: 100%;
        padding: 50px 0 ;
    }
}
