.rsbody {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 20px 10px;
}
.rsbody a {
    display: block;
    text-align: start;
    padding: 5px;
    font-size: clamp(0.1rem, 2vw, 1rem);
}
.rsBtnDiv {
    text-align: center;
    margin-bottom: 100px;
}

.rsBtn {
    width: 300px;
    height: 60px;
    background-color: #D9D9D9;
    border: none;
    border-radius: 5px;
    margin: 50px auto;
    font-size: 1.2rem;
    font-weight: 700;
    cursor: pointer;
}
.rsBtn:hover {
    background-color: #000000;
    color: #ffffff;
}
