.prdCardWrap{
  display: flex;
  flex-direction: row;
  width: 50%;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
}
.prdCardThumbImg{
  border: 2px solid #ffffff;
  width: 100%;
  height: auto;
}
.prdCardThumbImg:hover{
  /* border: 2px solid #000000; */
  /* border-radius: 2%; */
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  -ms-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  -o-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

/* sdk�ٿ� */
.prdCardDownloadText{
  /* border: 1px solid red; */
  position: absolute;
  width: 45%;
  height: 10%;
  left: 52%;
  bottom: 2.5%;
  z-index: 1;
  cursor: pointer;
}
/* ��ν���ٿ� */
.prdCardDownBrc {
  /* border: 1px solid blue; */
  position: absolute;
  width: 42%;
  height: 10%;
  left: 7%;
  bottom: 2.5%;
  z-index: 1;
  cursor: pointer;
}