@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 100;src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff2) format('woff2'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff) format('woff'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.otf) format('opentype');}

@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 300;src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2) format('woff2'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff) format('woff'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.otf) format('opentype');}

@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 400;src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2) format('woff2'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff) format('woff'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf) format('opentype');}

@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 500;src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2) format('woff2'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff) format('woff'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf) format('opentype');}

@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 700;src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2) format('woff2'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff) format('woff'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf) format('opentype');}

@font-face {font-family: 'Noto Sans KR';font-style: normal;font-weight: 900;src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff2) format('woff2'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff) format('woff'),url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.otf) format('opentype');}

* {
	font-family: 'Noto Sans KR', sans-serif;
  box-sizing: border-box;
  margin: 0;
}

#root {
  display: inline-block;
  width: 100%;
}
.container{
  margin: 0 auto;
  padding: 0;
}
#title{
  text-align: center;
  font-size: 1.6rem;
  font-weight: 900;
  margin: 50px;
}
#mobile-title{
  text-align: center;
  font-size: 5vw;
  font-weight: 900;
  margin: 8%;
}
.text-center{
  text-align: center;
}
.w-10{
  width: 10%;
}
.w-20{
  width: 20%;
}
.w-30{
  width: 30%;
}
.w-40{
  width: 40%;
}
.w-50{
  width: 50%;
}
.w-60{
  width: 60%;
}
.w-70{
  width: 70%;
}
.w-80{
  width: 80%;
}
.w-90{
  width: 90%;
}
.w-100{
  width: 100%;
}
.blue-font{
  color: #1782EA;
}

.sideBanner img {
  margin-bottom: -5px;
}


.popup {
  position: absolute;
  top: 100px;
  left: 400px;
  z-index: 999;
   width: 630px; 
  /*width: 930px;*/
  background-color: #f5f5f5;
}
.popup_02 {
  position: absolute;
  top: 100px;
  left: 400px;
  z-index: 999;
  width: 950px;
  background-color: #f5f5f5;
}
.popupTitle {
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
}
.popupBox {
  display: flex;
  background-color: #E5EAEC;
  padding: 10px 0;
}
/* .popupBox div {
  margin: 0 auto;
} */
.popupBox div {
  margin-left: 5px;
  /* margin-right: 0; */
}
/* .popupBoxLast {
  margin-left: 0;
  margin-right: 10px;
} */

.popupBtnBox{
  background-color:rgba(0,0,0,0.6);
  color:white;
  font-weight: 400;
  font-size: 0.9rem;
  padding: 5px;
}
.closeBtn {
  text-align: end;
  background-color:rgba(0,0,0,0.2);
  color:white;
  border: none;
  padding: 2px 10px;
}
.popupBtnA {
  display: flex;
  justify-content: space-between;
}


/* ������϶� */
@media screen and (max-width: 767px) {
  #root {
    width: 100%;
  }
  .sideBanner {
    display: none;
  }
  .sideBannerMobile {
    position: fixed;
    width: 100%;
    text-align: end;
    right: 10px;
    bottom: 10px;
    z-index: 2;
  }
}

/* ���϶� */
@media screen and (min-width: 768px) {
  .container {
    width: 1200px;
  }
  #title {
    max-width: 1200px;
  }
  .sideBanner {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 2;
    width: 239px;
  }
  .sideBannerMobile {
    display: none;
  }
}