.container {
  margin: 50px auto;
  text-align: center;
}

.success p:first-child {
  font-size: 1.5rem;
  margin: 24px 0;
}

.success p {
  margin: 0;
}

.sucBtn {
  width: 50%;
  max-width: 300px;
  margin: 50px auto;
  padding: 10px;
  border: none;
  border-radius: 3px;
  background-color: #1782EA;
  color: #FFFFFF;
  margin-bottom: 150px;
}
.sucBtn a {
  color: #FFFFFF;
  text-decoration: none;
}
