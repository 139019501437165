.pagination-buttons{
  text-align: center;
  margin: 10px 0 100px;
  display: flex;
  justify-content: center;
}

#page-btn{
  margin: 10px;
  font-size: 0.9rem;
  color: #A8A8A8;
  padding: 0;
  border: none;
  background-color: #ffffff;
  cursor: pointer;
}

#page-btn:hover{
  color: black;
}
#page-btn.active{
  font-weight: bold;
  color: black;
}

#prev-btn, #next-btn {
  border: none;
  background-color: #ffffff;
  padding: 0;
  margin: 0 15px;
  margin-top: 5px;
  cursor: pointer;
}

.d-none{
  /* display: none; */
}