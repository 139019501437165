.mainTitleDiv {
    display: flex;
    justify-content: center;
}
.mainTitle {
    text-align: center;
    font-size: 1.6rem;
    font-weight: 900;
    margin: 50px;

}
.customerMenusDiv {
    width: 100%;
    background-color: #EAEAEA;
}

.customerMenus {
    width: 50%;
    margin: 0 auto;
    text-align: center;
    background-color: #EAEAEA;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    font-size: 1.1rem;
}
.customerMenus > a {
    width: 100%;
    height: 50px;
    text-decoration: none;
    margin: 0 auto;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.customerMenus a:hover{
    background-color: black;
    color: white;
}
.customerMenus .activeTab {
    background-color: black;
    color: white;
    border: none;
}

.container {
    width: 50%;
    margin: 0 auto;
}

.title {
    text-align: left;
    font-weight: bold;
    font-size: 1.1rem;
}

.table {
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
    border-bottom: 1px solid black;
}
table.table > thead, table.detailTable > thead {
    border-top: 1px solid black;
}
table.table > thead th, table.detailTable > thead th {
    padding: 10px 0;
    border-bottom: 1px solid black;
}

.tableList a {
    text-decoration: none;
    color: black;
}
.tableList td {
    padding: 10px 0;
    border-top: 1px solid #EAEAEA;
    text-align: center;
}

.detailTable {
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
    border-bottom: 1px solid #EAEAEA;
}
table.detailTable > thead th, tbody td {
    text-align: start;
    padding: 10px;
}
table.detailTable > tbody td {
    vertical-align : top;
    padding-bottom: 200px;
}
table.detailTable > tbody td:last-child {
    color: #7C7B81;
}

.backBtnDiv{
    text-align: center;
    margin-top: 20px;
}
.backBtn, .checkBtn {
    width: 300px;
    height: 60px;
    margin: 30px auto;
    padding: 10px 50px;
    border: none;
    border-radius: 3px;
    background-color: black;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 1.2rem;
    cursor: pointer;
    margin-bottom: 150px;
}
.backBtn a {
    color: #FFFFFF;
    text-decoration: none;
    font-size: clamp(0.9rem, 2vw, 1.2rem);
}
 
@media screen and (min-width: 768px) {
    .mainTitle {
        max-width: 1200px;
    }
    .customerMenus {
        min-width: 1200px;
    }
    .title {
        margin-bottom: 20px;
    }
    .customerMenusDiv {
        margin-bottom: 100px;
    }
    .container {
        min-width: 1200px;
    }
}
/* @media screen and (max-width: 1200px) {
    .container {
        padding: 0 20px;
    }
} */

@media screen and (max-width: 767px) {
    .mainTitle {
        font-size: 5vw;
        font-weight: 900;
        margin: 8%;
    }
    .customerMenusDiv {
        background-color: #FFFFFF;
    }
    .customerMenus {
        width: 95%;
        text-align: center;
        background-color: #FFFFFF;
        color: black;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        border: 0.5px solid lightgray;
    }
    .customerMenus > a {
        text-decoration: none;
        width: 100%;
        padding: 5px 0;
        border: 0.5px solid lightgray;
    }
    .customerMenus a:hover{
        background-color: black;
        color: white;
        border: none;
    }
    .customerMenus .activeTab {
        border: none;
    }
    .title {
        margin-bottom: 20px;
    }
    .customerMenusDiv {
        margin-bottom: 50px;
    }
    .container {
        width: 95%;
        padding: 0;
        margin: 0 auto;
    }
    table.detailTable > thead th:first-child {
        width: 20%;
    }
}