#main-img {
  width: 100%;
  /* max-width: 100vw; */
  height: 500px;
  position: relative;
  background-color: black;
}
#main-img img {
  width: 1371px;
  min-width: 1200px;
  height: 500px;
  margin: 0 auto;
}

.text-center {
  font-size: 3rem;
  margin-bottom: 20px;
}

h1 {
  text-align: center;
}

#main-container {
  width: 1200px;
  height: auto;
  margin: 0 auto;
  /* position: relative; */
}

#main-reader-div-wrap{
  margin-top: 150px;
}
#main-reader-wrap{
  display: flex;
  margin-bottom: 150px;
}
#reader-main-img {
  margin-top: 10px;
  margin-right: 5px;
}
#readers div{
  margin: 10px;
}


.main-prd-div{
  margin: 70px 0 150px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  text-align: center;
}
.main-prd-div img{
  width: 95%;
  margin: 5px;
}
.main-prd-div img:hover{
  -webkit-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  -ms-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  -o-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.main-prd-plus {
  margin: 0 auto;
  margin-top: 10px;
  text-align: center;
}

#info-wrap{
  width: 1200px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 150px;
}

.info-div {
  position: relative;
}
.hover-on {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
.info-div:hover .hover-on{
  display: block;
}

/* .sideBanner {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1;
  width: 175px;
}
.sideBanner img {
  margin-bottom: -5px;
} */

.sub-img-div-main {
  width: 100%;
  text-align: center;
  margin-bottom: 150px;
}

.sub-img-div {
  width: 100%;
  text-align: center;
}
.sub-img-div img {
  width: 100%;
  margin-top: 40px;
}
.sub-img-div2 {
  width: 100%;
  text-align: center;
}
.sub-img-div3 {
  width: 100%;
  text-align: center;
  margin: 30px 0 150px;
}
.sub-img-div3 img {
  width: 75%;
}


@media screen and (min-width: 768px) { 
  #main-img {
    min-width: 1200px;
  }
  #main-container {
    min-width: 1200px;
  }
  .swiper {
    min-width: 1200px;
  }
}


.swiper {
  width: 100%;
  --swiper-theme-color:#fff;
}

.swiper-slide {
  text-align: center;
}

.swiper-pagination-bullet {
  --tw-ring-color: #fff;
}

.swiper-pagination-bullet {
  background : var(--swiper-pagination-bullet-inactive-color, #fff);
}