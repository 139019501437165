.selectBox {
    float: right;
}
.selectBox select {
    font-weight: bold;
    padding: 3px;
    border-radius: 0%;
    border: 1px solid #DADADA;
}
.selectBox option {
    text-align: left;
}
