.prdCtSelDiv{
  display: flex;
  flex-wrap: wrap;
  margin: 10px 20px;
}
.prdCtSelList{
  height: 9vw;
  width: 33%;
  border: 1px solid #DADADA;
  text-align: center;
  vertical-align: middle;
  font-size: 3.5vw;
  box-shadow: 0 0 0 2px transparent;
  cursor: pointer;
}
.prdCtSelList > span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; 
  white-space: nowrap;
  font-weight: 400;
}
/* .prdCtSmallFont > span {
  font-size: 2.6vw;
} */
.prdListDiv{
  width: 40%;
  height: auto;
  margin: 20px;
}
#prdListWrap{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 9vw;
}
#prdListWrap:last-child{
  margin-bottom: 150px;
}
#selectedSpec{
  width: 100%;
  display: none;
  flex-wrap: wrap;
  margin-top: 3%;  
}
.selectedSpecDiv{
  display: flex;
  background-color: #1782EA;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 1.3vw;
  padding: 1% 2%;
  margin: 0px 10px 10px 0;
  
}
.selectedClose{
  width: 2vw;
  margin-left: 1.4vw;
}
.nonCheckedCategory{
  color: #D1D2D6;
}
.checkedCategory{
  color: black;
}
.searchResult{
  width: 100%;
  text-align: center;
  margin: 5% 0;
  font-weight: 500;
  font-size: 4vw;
}
.searchResult2 {
  text-align: center;
  margin-bottom: 5%;
}

.listImages {
  width: 100%;
  display: flex;
  justify-content: space-between;
  
}
.listImages img{
  width: 32.5%;
}

#noneListWrap {
  width: 100%;
}
#noneListWrap img{
  width: 100%;
}


/* ===========================================모달 css */
.modalContainer{
  position: relative;
  /* height: 60vh; */
}
.modalHeader{
  text-align: left;
  font-size: 5vw;
  font-weight: 900;
  padding: 4% 0 4% 6%;
}
.modalContent{
  display: flex;
  height: 80%;
  /* overflow-y: auto;   */
  width: 100%;
  flex-wrap: wrap;
}
.modalCategory{
  width: 30%;
  max-height: 100%;
  cursor: pointer;
}
.modalCategory > div{
  width: 100%;
  font-size: 3.5vw;
  padding: 0.5vh 10%;
  margin: 0.5vh 10%;
  font-weight: 900;
}
.modalSpec{
  width: 65%;
  background-color: #F2F2F2;
  margin-right: 3%;
  overflow-y: auto;  
  overflow-x: hidden;  
  max-height: 100%;
  padding-bottom: 20vw;
}
.modalFooter{
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 8vh;
  background-color: white;
  border-top: 1px solid #DADADA;
  align-items: center;
  justify-content: center;
}
.modalSearchBtn{
  width: 90%;
  height: 65%;
  border-radius: 5px;
  background: #1782EA;
  color: white;
  font-size: 4vw;
  border: none;
  cursor: pointer;
}
.modalSpecSection{
  margin: 5px;
}
.modalSpecNameDiv{
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #DADADA;
  background: #FFF;
  margin: 5px;
  padding: 1.5% 2.7%;
  font-size: 2vw;
}
.modalCtNameDiv{
  margin: 2%;
  margin-top: 4%;
  font-weight: 600;
  font-size: 3.5vw;
}
.modalSpecDiv{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  font-size: 2.8vw;
  cursor: pointer;
}
.checked{
  color: #FFF;
  background-color: #1782EA;
}

