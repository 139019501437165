#mobileHeaderWrap{
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  box-shadow: 2px 1px 4px 0px rgb(178, 178, 178);
}
#headerLogo{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2vw;
  margin: 1vw;
}
#navMenu * {
  text-decoration: none;
  /* width: 14.3%; */
}
#navMenu{
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: space-between;
}
#navMenu li{
  cursor: pointer;
  width: 100%;
  color: black;
  text-align: center;
  font-weight: 700;
  font-size: 2.8vw;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  padding: 2vw 1vw;
}
#navMenu li:hover {
  background-color: #000000;
  color: #ffffff;
}
#navMenu a div {
  width: 100%;
}
#navMenu a div ul {
  position: fixed;
  z-index: 999;
  background-color: #eaeaea;
  padding: 0;
  margin: 0;
  width: 13%;
}
#navMenu a div ul li {
  text-decoration: none;
  list-style: none;
  font-size: 2vw;
  font-weight: 400;
  padding: 5px 0;
}
#navMenu a div ul li:hover {
  background-color: #eaeaea;
  color: #000000;
  font-weight: bold;
}

/* #navSubMenu {
  display: flex;
}
#navSubMenu div {
  width: 46%;
}
.customerSubMenu {
  width: 100%;
  padding: 0;
  margin-bottom: 10px;
}
.customerSubMenu a {
  text-decoration: none;
  list-style: none;
  color: black;
}
.customerSubMenu a li {
  font-size: 2vw;
  font-weight: 400;
}
.customerSubMenu a li:hover {
  color: black;
  font-weight: bold;
}  */

#mainLogo{
  display: flex;
  width: 30vw;
  padding: 1vw;
  align-items: center;
}
#searchIcon{
  width: 23%;
  padding: 0.8vw;
  height: 6vw;
}
#searchInput{
  height: 100%;
  width: 100%;
  outline: none;
  border-style: none;
  border-bottom: 1px solid #BCBCBC;
}
#searchWrap{
  display: flex;
  width: 30vw;
}
#searchInputDiv{
  width: 70%;
}
