.prdCtDiv *{
  margin: 1.5px;
}
.prdCtDiv{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.ctNameDiv{
  font-size: 1.2rem;
  font-weight: 900;
  padding-top: 10px;
  margin-left: 10px;
}
.prdCtDiv:not(:last-of-type){
  border-bottom: 1px solid rgb(206, 206, 206);
}
.prdCtDiv:last-of-type{
  border-bottom: 1px solid #9A9A9A;
  box-shadow: 0 1px 0 0 #EEEEEE;
  margin-bottom: 15px;
}
.prdSpecDiv{
  width: 82%;
  text-align: left;
}
.prdSpecDiv label{
  display: inline-block;
  width: 20%;
  min-width: 192px;
  /* margin: 8px 0; */
  margin: 8px 0 15px ;
  /* margin: 8px 15px 15px 10px;  */
}
.prdCtList{
  margin-right: 8px;
  appearance: none;
  width: 1rem;
  height: 1rem;
  border: 3px solid black;
  /* transition: background 0.2s; */
  position: relative;
  top: 5.5px;
  cursor: pointer;
}
.prdCtList:checked {
  background: black;
  border: none;
}
.specSpan{
  cursor: pointer;
  font-weight: 300;
  /*letter-spacing: 0;*/
}
.specSpan:hover{
  font-weight: bold;
  /* color: red; */
  /*letter-spacing: -0.7px;*/
}

.detailBtnDiv {
  text-align: center;
}
.detailBtn {
  border: none;
  background-color: #ffffff;
  cursor: pointer;
  font-size: 1.2rem;

}
.detailBtn:hover{
  font-weight: 900;
}

#searchBtnDiv{
  text-align: center;
  margin-top: 80px;
}
#searchBtn{
  border: none;
  background-color: #1782EA;
  color: white;
  border-radius: 5px;
  width: 300px;
  height: 60px;
  font-weight: 700;
  font-size: 1.2rem;
  cursor: pointer;
}
#searchBtn:hover{
  background-color: #1569bc;
}

#prdListWrap{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
#prdListWrap:last-child{
  margin-bottom: 150px;
}

#noneListWrap {
  margin: 0 auto;
  margin-top: 80px;
}

.searchResult{
  text-align: center;
  font-size: 1.7rem;
  margin-bottom: 30px;
}
.searchResult2 {
  text-align: center;
  margin: 0 auto;
  font-size: 1.3rem;
  margin-bottom: 50px;
}

.listImages {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.listImages img {
  width: 32.5%;
}




















